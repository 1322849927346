<template>
  <div
    style="height: 100vh; background-color: white; color: black"
    class="p-0 d-flex flex-column justify-content-around"
  >
    <section
      id="middleScrolling"
      class="scrolling-section"
      style="overflow-y: auto"
    ></section>
  </div>
</template>

<script>
import { contactRootRef, fiducialRef } from "../utils/firebase";

const DEFAULT_VIEW = "imp-echo-francis-welcome";
export default {
  components: {},
  props: {
    user: { type: Object, required: true },
    view: { type: String, required: false, default: null },
  },
  data() {
    return {};
  },
  computed: {},
  async created() {
    const view =
      this.view ||
      (
        await contactRootRef.child(this.user.id).child("view").once("value")
      ).val() ||
      DEFAULT_VIEW;

    const projectClass =
      (
        await fiducialRef
          .child(view)
          .child("config")
          .child("projectClass")
          .once("value")
      ).val() || "fiducial"; // Originally, there was no "projectClass": all projects were fiducial

    if (projectClass === "compare") {
      this.$router.replace({ name: "Compare", params: { view: view } });
    } else if (projectClass === "timing") {
      this.$router.replace({ name: "Temporal", params: { view: view } });
    } else if (projectClass === "fiducial") {
      this.$router.replace({ name: "Fiducial", params: { view: view } });
    } else {
      throw new Error("Unrecognised project class: " + projectClass);
    }
  },
  methods: {
    //
    //  ______ _          _
    //  |  ___(_)        | |
    //  | |_   _ _ __ ___| |__   __ _ ___  ___
    //  |  _| | | '__/ _ \ '_ \ / _` / __|/ _ \
    //  | |   | | | |  __/ |_) | (_| \__ \  __/
    //  \_|   |_|_|  \___|_.__/ \__,_|___/\___|
    //

    //
    //   _   _                            _   _
    //  | | | |                          | | (_)
    //  | | | |___  ___ _ __    __ _  ___| |_ _  ___  _ __
    //  | | | / __|/ _ \ '__|  / _` |/ __| __| |/ _ \| '_ \
    //  | |_| \__ \  __/ |    | (_| | (__| |_| | (_) | | | |
    //   \___/|___/\___|_|     \__,_|\___|\__|_|\___/|_| |_|
    //

    //
    //  ______                         _
    //  |  ___|                       | |
    //  | |_ ___  _ __ _ __ ___   __ _| |_
    //  |  _/ _ \| '__| '_ ` _ \ / _` | __|
    //  | || (_) | |  | | | | | | (_| | |_
    //  \_| \___/|_|  |_| |_| |_|\__,_|\__|
    //

    //   _   _             _             _   _
    //  | \ | |           (_)           | | (_)
    //  |  \| | __ ___   ___  __ _  __ _| |_ _  ___  _ __
    //  | . ` |/ _` \ \ / / |/ _` |/ _` | __| |/ _ \| '_ \
    //  | |\  | (_| |\ V /| | (_| | (_| | |_| | (_) | | | |
    //  \_| \_/\__,_| \_/ |_|\__, |\__,_|\__|_|\___/|_| |_|
    //                        __/ |
    //                       |___/

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
